// extracted by mini-css-extract-plugin
export var checkbox = "CreateBareMetalProjectConfiguration__checkbox__VpAJe";
export var checkboxRightMargin = "CreateBareMetalProjectConfiguration__checkboxRightMargin__lkoKY";
export var checkboxRow = "CreateBareMetalProjectConfiguration__checkboxRow__ebIo6";
export var column = "CreateBareMetalProjectConfiguration__column__hrUMJ";
export var docUploadDescription = "CreateBareMetalProjectConfiguration__docUploadDescription__cVtUn";
export var docUploadSection = "CreateBareMetalProjectConfiguration__docUploadSection__Fv0cn";
export var docUploadTitle = "CreateBareMetalProjectConfiguration__docUploadTitle__FElVG";
export var documentRow = "CreateBareMetalProjectConfiguration__documentRow__UdUql";
export var elongated = "CreateBareMetalProjectConfiguration__elongated__pFjiB";
export var errorLabel = "CreateBareMetalProjectConfiguration__errorLabel__fUfW4";
export var errorMessage = "CreateBareMetalProjectConfiguration__errorMessage__zEt7C";
export var expanded = "CreateBareMetalProjectConfiguration__expanded__gXLDi";
export var field = "CreateBareMetalProjectConfiguration__field__fEeHH";
export var fieldRow = "CreateBareMetalProjectConfiguration__fieldRow__o4deF";
export var fieldWithUnit = "CreateBareMetalProjectConfiguration__fieldWithUnit__Wxfy3";
export var flex = "CreateBareMetalProjectConfiguration__flex___oA5M";
export var flexColumn = "CreateBareMetalProjectConfiguration__flexColumn__BRQZe";
export var flexContainerDoc = "CreateBareMetalProjectConfiguration__flexContainerDoc__Rm3IO";
export var gap1 = "CreateBareMetalProjectConfiguration__gap1__coEfe";
export var gap2 = "CreateBareMetalProjectConfiguration__gap2___n2_m";
export var gap3 = "CreateBareMetalProjectConfiguration__gap3__nrU4y";
export var gap4 = "CreateBareMetalProjectConfiguration__gap4__tffm6";
export var gap5 = "CreateBareMetalProjectConfiguration__gap5__D6LUn";
export var geographicalRegionsDescription = "CreateBareMetalProjectConfiguration__geographicalRegionsDescription__vt5S2";
export var geographicalRegionsSection = "CreateBareMetalProjectConfiguration__geographicalRegionsSection__BL1IQ";
export var geographicalRegionsTitle = "CreateBareMetalProjectConfiguration__geographicalRegionsTitle___8s7d";
export var iconView = "CreateBareMetalProjectConfiguration__iconView___a8eR";
export var input = "CreateBareMetalProjectConfiguration__input__aCr7c";
export var inputWithUnitUnit = "CreateBareMetalProjectConfiguration__inputWithUnitUnit__EC6Jz";
export var inputWithUnitUnitContainer = "CreateBareMetalProjectConfiguration__inputWithUnitUnitContainer__iQKXd";
export var inputWithUnitValue = "CreateBareMetalProjectConfiguration__inputWithUnitValue__uAGKG";
export var inputWithUnitValueContainer = "CreateBareMetalProjectConfiguration__inputWithUnitValueContainer__RiuHm";
export var justified = "CreateBareMetalProjectConfiguration__justified__Qvsw1";
export var label = "CreateBareMetalProjectConfiguration__label__wYLKi";
export var moderate = "CreateBareMetalProjectConfiguration__moderate__Outob";
export var radioButton = "CreateBareMetalProjectConfiguration__radioButton__qKLf2";
export var radioButtonLabel = "CreateBareMetalProjectConfiguration__radioButtonLabel__HWBAo";
export var radioButtonsRow = "CreateBareMetalProjectConfiguration__radioButtonsRow__aSfyT";
export var requiredLabel = "CreateBareMetalProjectConfiguration__requiredLabel__EdZ2a";
export var row = "CreateBareMetalProjectConfiguration__row___MgJm";
export var selectWrapper = "CreateBareMetalProjectConfiguration__selectWrapper__fKsZF";
export var subHeading = "CreateBareMetalProjectConfiguration__subHeading__SPalN";
export var textarea = "CreateBareMetalProjectConfiguration__textarea__c0P6Y";
export var title = "CreateBareMetalProjectConfiguration__title__UiR2p";
export var tooltipAndHeadingWrapper = "CreateBareMetalProjectConfiguration__tooltipAndHeadingWrapper__PeA0y";
export var tooltipDescription = "CreateBareMetalProjectConfiguration__tooltipDescription__pJhiv";
export var unfilled = "CreateBareMetalProjectConfiguration__unfilled__pfgI6";
export var unitSelectWrapper = "CreateBareMetalProjectConfiguration__unitSelectWrapper__N0uR4";
export var uploadDocumentButton = "CreateBareMetalProjectConfiguration__uploadDocumentButton__JR8rY";
export var uploader = "CreateBareMetalProjectConfiguration__uploader__eF8rz";