// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNotifications from "react-notifications";

var Container = {};

function success(message, title, timeout) {
  ReactNotifications.NotificationManager.success(message, title, timeout);
}

function error(message, title, timeout) {
  ReactNotifications.NotificationManager.error(message, title, timeout);
}

var NotificationManager = {
  success: success,
  error: error
};

var Manager = {};

export {
  Container ,
  NotificationManager ,
  Manager ,
}
/* react-notifications Not a pure module */
